<template>
  <div>
    <v-btn @click="addItem()">Add item</v-btn>
    <div
      class="container"
      ref="container"
    >
      <div
        v-for="(item, key) in computedItems"
        :key="key"
        class="box"
        draggable
        @dragstart="handleDragStart($event, key)"
        @dragenter="handleDragEnter"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop($event, item, key)"
        @dragend="handleDragEnd"
      >
        {{ item.name }} {{ item.precedence }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    names: ["Lorem", "Ipsum", "Dolor"],
    items: [],
    dragSrcEl: null,
    draggedItem: null
  }),
  computed: {
    computedItems() {
      return this.items;
    }
  },
  methods: {
    addItem() {
      // let id = this.items.length++;

      this.items.push({
        // id,
        name: this.names[this.items.length],
        precedence: this.items.length
      });
    },
    handleDragStart(e, key) {
      e.srcElement.style.opacity = '0.4';
      
      this.dragSrcEl = e.srcElement;
      this.draggedItem = key;

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.srcElement.innerHTML);
    },
    handleDragOver(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = 'move';
      
      return false;
    },
    handleDragEnter(e) {
      e.srcElement.classList.add('over');
    },
    handleDragLeave(e) {
      e.srcElement.classList.remove('over');
    },
    handleDragEnd(e) {
      e.srcElement.style.opacity = '1';

      this.$refs.container.children.forEach(function (item) {
        item.classList.remove('over');
      });
      
    },
    handleDrop(e, item, key) {
    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }

    let dragSrcEl = e.srcElement;

    if (dragSrcEl != this.dragSrcEl) {
      // dragSrcEl.innerHTML = this.dragSrcEl.innerHTML;
      // this.dragSrcEl.innerHTML = e.dataTransfer.getData('text/html');
      
      /**
       * Switching array places
       */
      this.items[key] = this.items.splice(this.draggedItem, 1, this.items[key])[0]

      console.log(this.items);
    }
    
    return false;
  }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.box {
  border: 3px solid #666;
  background-color: #ddd;
  border-radius: .5em;
  padding: 10px;
  cursor: move;
}

.box.over {
  border: 3px dotted #666;
}

[draggable] {
  user-select: none;
}
</style>